import { type ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { sessionContext } from "@/context";

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { userToken } = useContext(sessionContext);
  const { pathname, search } = useLocation();

  const isLoggedIn = !(userToken === null);

  if (!isLoggedIn) {
    const next = encodeURIComponent(`${pathname}${search}`);
    return <Navigate to={`/?next=${next}`} replace />;
  }

  return <>{children}</>;
};
