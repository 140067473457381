import { Navigate, useParams, type RouteObject } from "react-router-dom";
import { checkoutRoutes, oldCheckoutRoutes, newProfileRoutes } from "./routes";
import { CheckoutProvider } from "@/context";
import { ECheckoutURL } from "@/hooks/useCheckout/types";

const RedirectOrderId = () => {
  const { orderId } = useParams();
  return <Navigate to={`/orders/${orderId}`} replace />;
};

const privateRoutes: RouteObject[] = [
  // Without MainLayout
  {
    path: "checkout",
    lazy: async () => {
      const { default: CheckoutLayout, loader } = await import("@/components/Layouts/CheckoutLayout");
      return {
        loader,
        Component: CheckoutLayout,
        shouldRevalidate: (args) => {
          if (
            !args.currentUrl.pathname.startsWith("/checkout") ||
            [ECheckoutURL.PSE_PAYMENT_PAGE, ECheckoutURL.SUCCESS_PAGE].includes(
              args.currentUrl.pathname as ECheckoutURL
            )
          ) {
            return true;
          }

          return args.nextUrl.pathname === ECheckoutURL.REVIEW_PAGE;
        },
      };
    },
    children: checkoutRoutes,
  },
  {
    path: "shopping-cart",
    lazy: async () => {
      const { default: ShoppingCart } = await import("@/Pages/ShoppingCart");
      return {
        element: <ShoppingCart />,
      };
    },
    shouldRevalidate: () => true,
  },
  {
    path: "profile",
    lazy: async () => {
      const { default: ProfileLayout } = await import("@/components/Layouts/ProfileLayout");
      return {
        element: <ProfileLayout />,
      };
    },
    children: newProfileRoutes,
  },
  // With MainLayout
  {
    path: "/",
    lazy: async () => {
      const { default: MainLayout } = await import("@/components/Layouts/MainLayout");
      return {
        element: <MainLayout />,
      };
    },
    children: [
      {
        path: "orders",
        lazy: async () => {
          const { default: Orders } = await import("@/Pages/Orders");
          return {
            element: <Orders />,
          };
        },
      },
      {
        path: "orders/:orderId",
        lazy: async () => {
          const { default: Order } = await import("@/Pages/Order");
          return {
            element: <Order />,
          };
        },
      },
    ],
  },
  // Redirect from old pages
  {
    path: "orders/:orderId/rate",
    element: <RedirectOrderId />,
  },
];

// Old routes
const oldPrivateRoutes: RouteObject[] = [
  // {
  //   path: "profile/plus",
  //   lazy: async () => {
  //     const { ProfileMembership } = await import("@/screens/Profile/ProfileMembership");
  //     return {
  //       element: <ProfileMembership />,
  //     };
  //   },
  // },
  {
    path: "referral",
    lazy: async () => {
      const { Referral } = await import("@/screens/Referral");
      return {
        element: <Referral />,
      };
    },
  },
  // {
  //   path: "payment-method/validate",
  //   lazy: async () => {
  //     const { PaymentMethodValidate } = await import("@/screens/PaymentMethodValidate");
  //     return {
  //       element: <PaymentMethodValidate />,
  //     };
  //   },
  // },
  {
    path: "referred",
    lazy: async () => {
      const { Referred } = await import("@/screens/Referred");
      return {
        element: <Referred />,
      };
    },
  },
  // {
  //   path: "orders-old",
  //   lazy: async () => {
  //     const { Orders } = await import("@/screens/Orders");
  //     return {
  //       element: <Orders />,
  //     };
  //   },
  // },
  // {
  //   path: "orders-old/:orderId",
  //   lazy: async () => {
  //     const { Order } = await import("@/screens/Order");
  //     return {
  //       element: <Order />,
  //     };
  //   },
  // },
  {
    path: "checkout-old",
    Component: CheckoutProvider,
    children: oldCheckoutRoutes,
  },
];

export { privateRoutes, oldPrivateRoutes };
