import Modal from "@/components/Atoms/Modal";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import { StyledConfirmLogoutGrid, StyledConfirmLogoutWrapper } from "./styles";
import IconAuth from "@/components/Atoms/IconAuth";
import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";
import Button from "@/components/Atoms/Button";
import tw from "twin.macro";
import { useLogout } from "@/hooks/useLogout";

interface ConfirmLogoutProps {
  isOpen: boolean;
  handleClose: () => void;
  onLogout?: () => void;
}

const ConfirmLogout = ({ isOpen, handleClose, onLogout }: ConfirmLogoutProps) => {
  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
    handleClose();
    onLogout?.();
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      showCenter
      classes={{
        card: tw`p-0
        w-[85%]  medium:(w-[23.5rem])
        `,
        backdrop: tw`z-[52]`,
      }}
    >
      <StyledConfirmLogoutWrapper>
        <IconClose tw="h-ml w-ml justify-self-end cursor-pointer self-end" onClick={handleClose} />
        <StyledConfirmLogoutGrid>
          <IconAuth type="exit" />
          <Heading type="h2">Cerrar sesión</Heading>
          <Paragraph sizeVariant="md">¿Deseas cerrar tu sesión?</Paragraph>
          <Button color="secondary" sizeVariant="lg" onClick={handleLogout}>
            Cerrar sesión
          </Button>
          <Button color="secondary" sizeVariant="lg" variant="text" onClick={handleClose}>
            Cancelar
          </Button>
        </StyledConfirmLogoutGrid>
      </StyledConfirmLogoutWrapper>
    </Modal>
  );
};
export default ConfirmLogout;
