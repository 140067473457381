import { useEffect } from "react";
import { genericsAction } from "@/store/generics";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import useQueryParams from "./useQueryParams";
import useSessionContext from "./useSesionContext";
import { useNavigate } from "react-router-dom";
import { type AuthStep } from "@/components/Navbar/AuthModal/types";

export enum CALLBACK_MODAL_AUTH {
  NAVIGATE_TO_CHECKOUT = "NAVIGATE_TO_CHECKOUT",
}
interface HandleOpenModalAuthProps {
  callbackIdentifier?: CALLBACK_MODAL_AUTH | undefined;
  initialStep?: AuthStep;
}

const useModalAuth = () => {
  const navigate = useNavigate();

  const { isAthenticated } = useSessionContext();
  const dispatch = useAppDispatch();
  const openModalAuth = useAppSelector((state) => state.generics.modalAuth.openModalAuth);
  const callbackIdentifier = useAppSelector((state) => state.generics.modalAuth.callbackIdentifier);

  const { queryParams } = useQueryParams();
  const isSignUpQueryParamPresent = Boolean(queryParams.get("open_signup"));
  const isLogInQueryParamPresent = Boolean(queryParams.get("open_login"));
  const nextRouteParam = queryParams.get("next");

  useEffect(() => {
    if (isAthenticated()) {
      return;
    }
    if (isSignUpQueryParamPresent) {
      handleOpenModalAuth({ initialStep: "phoneNumber" });
    }
    if (isLogInQueryParamPresent) {
      handleOpenModalAuth({ initialStep: "login" });
    }
  }, [isAthenticated()]);

  const handleOpenModalAuth = ({ callbackIdentifier, initialStep }: HandleOpenModalAuthProps = {}) => {
    dispatch(genericsAction.setOpenModalAuth({ open: true, callbackIdentifier, initialStep }));
  };

  const handleCloseModalAuth = () => {
    dispatch(genericsAction.setOpenModalAuth({ open: false }));
  };

  const handleCallback = () => {
    if (callbackIdentifier === CALLBACK_MODAL_AUTH.NAVIGATE_TO_CHECKOUT) {
      navigate("/checkout");
    }

    if (nextRouteParam && !callbackIdentifier) {
      const decodedNextRoute = decodeURIComponent(nextRouteParam);

      // The `navigate` was not working (it was clearing the query params)
      window.location.href = decodedNextRoute;
    }

    dispatch(genericsAction.setCallbackAuthIdentifier(undefined));
  };

  return {
    isAthenticated,
    isOpen: openModalAuth,
    handleOpenModalAuth,
    handleCloseModalAuth,
    handleCallback,
  };
};

export default useModalAuth;
