/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from "react";

import getAcces from "@/assets/common/fithub-product-02.webp";
import welcome from "@/assets/common/fithub-products.webp";
import tw, { css } from "twin.macro";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createPhoneCode, loginUser, validatePhoneCode, changePassword } from "@/services/auth";
import { type AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { userSlice } from "@/store/user";
import Analytics from "@/utils/Analytics";
import { type AuthFormFields, type AuthStep } from "@/components/Navbar/AuthModal/types";
import Modal from "@/components/Atoms/Modal";
import { StyledWrapperStepsModal } from "@/components/Organisms/AuthStepsModal/styles";
import Login from "@components/Organisms/Login";
import ConfirmPhoneCode from "@components/Organisms/ConfirmPhoneCode";
import SendPhoneCode from "@/components/Organisms/SendPhoneCode";
import PasswordUpdate from "@/components/Organisms/PasswordUpdate";
import AuthStepsImg from "@/components/Molecules/AuthStepsImg";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import useSessionContext from "@/hooks/useSesionContext";
import useModalAuth from "@/hooks/useModalAuth";
import SignUp from "../SignUp";
import useAppSelector from "@/hooks/useAppSelector";
import useQueryParams from "@/hooks/useQueryParams";
import { identify, login as loginAnalytics } from "@utils/analyticsV2";

interface AuthProps {
  onModalClose: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  children?: React.ReactNode;
  dismissable: boolean;
  isOpen: boolean;
  isCheckout?: boolean;
  titleLogin?: string;
  onSuccess?: () => void;
}

// FIXME: refactor code
const initialFetchingError = [];
const AuthModal = ({
  onSuccess,
  onModalClose,
  isOpen,
  dismissable,
  titleLogin,
}: AuthProps): React.ReactElement => {
  const isMobile = useMediaQuery("(max-width:{md})");

  const initialStep = useAppSelector((state) => state.generics.modalAuth.initialStep);
  const [step, setStep] = useState<AuthStep>(initialStep ?? "login");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tokenUserRegistration, setTokenUserRegistration] = useState("");
  const { setUserToken, isAthenticated } = useSessionContext();

  const isLoggedIn = isAthenticated();
  const { handleCallback } = useModalAuth();
  const { setQueryParams } = useQueryParams();

  const [fetchingError, setFetchingError] = useState<string[] | string>(initialFetchingError);

  const cleanErrors = () => setFetchingError(initialFetchingError);

  const onAuthModalClose = (): void => {
    onModalClose();
    setStep("login");
    setQueryParams({ next: null });
  };
  const updateCurrentUser = (data: Omit<UserAttr, "token">): any =>
    dispatch(
      userSlice.actions.setUser({
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.telephone,
        creditAvaliable: {
          gross: data.credit_available.gross,
          grossLocalized: data.credit_available.gross_localized,
          net: data.credit_available.net,
          netLocalized: data.credit_available.net_localized,
          currency: data.credit_available.currency,
        },
        referralCreditAvailable: data.referral_credit_available
          ? {
              gross: data.referral_credit_available.gross,
              grossLocalized: data.referral_credit_available.gross_localized,
              net: data.referral_credit_available.net,
              netLocalized: data.referral_credit_available.net_localized,
              currency: data.referral_credit_available.currency,
            }
          : null,
        birthday: data.dob,
        gender: data.gender,
        hearAboutUs: data.hear_about_us_source,
        publicId: data.public_id,
        identification: data.identification,
        identificationType: data.identification_type,
        membership_savings: data.membership_savings,
        membership: data.membership,
      })
    );
  const { control, handleSubmit, register, getValues, errors, clearErrors, formState, watch } =
    useForm<AuthFormFields>({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {},
    });

  const [login] = useMutation(loginUser, {
    onSuccess: ({ token, user }) => {
      setFetchingError(initialFetchingError);
      if (onSuccess) {
        onSuccess();
        loginAnalytics.loginBasic({
          userId: String(user?.id),
        });
      }
      setLoading(false);
      updateCurrentUser(user);
      setUserToken(token);
      handleCallback();

      // if (location.test) emptyLocation();

      // Identify users in all analytics tools
      identify({
        id: String(user.id),
        user: {
          email: user.email,
          firstName: `${user.first_name}`,
          lastName: `${user.last_name}`,
          phone: user.telephone,
          createdAt: user.date_joined,
          membership: user.membership
            ? {
                code: user.membership.code,
                planId: user.membership.plan,
                planName: user.membership.plan_name,
                status: user.membership.status,
              }
            : null,
        },
      });
      onAuthModalClose();
    },
    onError: (error: any) => {
      setFetchingError(error.response.data.non_field_errors as string[]);
      const userAgent = navigator.userAgent;
      const { email, password } = getValues();
      Analytics.LoginAttempt({
        agent: userAgent,
        email,
        password,
      });
      setLoading(false);
    },
  });

  const [sendCodeMsg] = useMutation(createPhoneCode, {
    onSuccess: (data) => {
      store?.type === "new_user" ? setStep("phoneCode") : setStep("phoneCodePassword");
    },
    onError: (error: AxiosError<{ telephone: string }>) => {
      setFetchingError(error.response?.data?.telephone as unknown as string[]);
    },
  });

  const [emailUser, setEmailUser] = useState("");

  const [validateCode, { data }] = useMutation(validatePhoneCode, {
    onSuccess: (data: { email?: string; token?: string }) => {
      if (data?.email) {
        setEmailUser(data.email);
      }
      if (data?.token) {
        setTokenUserRegistration(data.token);
      }
      store?.type === "new_user" ? setStep("signUp") : setStep("passwordUpdate");
    },
    onError: (error: AxiosError<{ telephone: string; code: any }>) => {
      setFetchingError(error.response?.data?.code as string[]);
    },
  });

  const [updatePassword] = useMutation(changePassword, {
    onSuccess: (data) => {
      setStep("login");
    },
    onError: (error: any) => {
      setFetchingError(
        (error.response.data.detail as string[]) ||
          (error.response.data.confirm_password as string[]) ||
          (error.response.data?.user as string[])
      );
    },
  });

  // Persist form state between submits.
  const [store, setStore] = useState<{ phoneNumber: string; type: string }>();

  const onSubmit = async ({
    password,
    phoneCode,
    phoneNumber,
    email,
    newPassword,
    channel,
  }: AuthFormFields) => {
    if (step === "login") {
      setLoading(true);
      await login({ email, password });
      setLoading(false);
      return;
    }
    if (step === "signUp") {
      return;
    }
    if (step === "phoneCode" && store) {
      setLoading(true);
      await validateCode({ phoneNumber: store.phoneNumber, code: phoneCode });
      setLoading(false);
      return;
    }
    if (step === "phoneCodePassword" && store) {
      setLoading(true);
      await validateCode({ phoneNumber: store.phoneNumber, code: phoneCode });
      setLoading(false);
      return;
    }
    if (step === "phoneNumber") {
      setLoading(true);
      await setStore({ phoneNumber: `+${phoneNumber}`, type: "new_user" });
      await sendCodeMsg({ phoneNumber: `+${phoneNumber}`, type: "new_user", channel });
      setLoading(false);
    }
    if (step === "phoneNumberPassword") {
      setLoading(true);
      await setStore({ phoneNumber: `+${phoneNumber}`, type: "change_password" });
      await sendCodeMsg({ phoneNumber: `+${phoneNumber}`, type: "change_password", channel });
      setLoading(false);
    }
    if (step === "passwordUpdate") {
      setLoading(true);
      await updatePassword({
        newPassword,
        token: data?.token,
      });
      setLoading(false);
    }
  };

  // TODO: Mejorar esto mas adelante
  const triggerSubmit = async (extraData: Record<string, any> = {}) =>
    await onSubmit({ ...extraData, ...getValues() });

  const getStep: Record<string, React.ReactElement> = {
    login: (
      <Login
        controller={{ register, errors, formState }}
        setView={setStep}
        title={titleLogin}
        loading={loading}
        onClose={onAuthModalClose}
        fetchingError={fetchingError}
        cleanErrors={cleanErrors}
        isMobile={isMobile}
      />
    ),
    phoneCode: (
      <ConfirmPhoneCode
        setStep={setStep}
        phoneNumber={store?.phoneNumber ?? ""}
        controller={{ control, errors, formState }}
        loading={loading}
        type="signUp"
        fetchingError={fetchingError}
        cleanErrors={cleanErrors}
        onClose={onAuthModalClose}
        isMobile={isMobile}
      />
    ),
    phoneCodePassword: (
      <ConfirmPhoneCode
        setStep={setStep}
        phoneNumber={store?.phoneNumber ?? ""}
        controller={{ control, errors, formState }}
        loading={loading}
        type="recovery"
        fetchingError={fetchingError}
        cleanErrors={cleanErrors}
        onClose={onAuthModalClose}
        isMobile={isMobile}
      />
    ),
    phoneNumber: (
      <SendPhoneCode
        onClose={onAuthModalClose}
        controller={{ clearErrors, errors, control, formState, register, getValues }}
        setStep={setStep}
        type="signUp"
        onSubmit={triggerSubmit}
        loading={loading}
        fetchingError={fetchingError}
        cleanErrors={cleanErrors}
        isMobile={isMobile}
      />
    ),
    phoneNumberPassword: (
      <SendPhoneCode
        onClose={onAuthModalClose}
        controller={{ clearErrors, errors, control, formState, register, getValues }}
        setStep={setStep}
        type="recovery"
        onSubmit={triggerSubmit}
        loading={loading}
        fetchingError={fetchingError}
        cleanErrors={cleanErrors}
        isMobile={isMobile}
      />
    ),

    signUp: (
      <SignUp
        onClose={onAuthModalClose}
        isMobile={isMobile}
        updateCurrentUser={updateCurrentUser}
        setStep={setStep}
        tokenUserRegistration={tokenUserRegistration}
      />
    ),
    passwordUpdate: (
      <PasswordUpdate
        email={emailUser}
        controller={{ register }}
        loading={loading}
        onClose={onAuthModalClose}
        fetchingError={fetchingError}
        cleanErrors={cleanErrors}
        isMobile={isMobile}
        setStep={setStep}
      />
    ),
  };

  useEffect(() => {
    cleanErrors();
  }, [step]);
  return (
    <Modal
      isOpen={isOpen && !isLoggedIn}
      // dismissable={dismissable}
      handleClose={onAuthModalClose}
      classes={{
        backdrop: css`
          ${tw`overflow-auto z-[52]`}
          ${isMobile ? tw`block` : tw`flex`}
        `,
        card: css`
          ${tw`p-0`}
          ${!isMobile ? tw`rounded-xl` : tw`rounded-none w-full  `}
        `,
      }}
    >
      <StyledWrapperStepsModal>
        {!isMobile && (
          <AuthStepsImg
            src={step === "login" || step === "signUp" ? welcome : getAcces}
            alt="Man typing on his phone"
          />
        )}
        {step === "signUp" && (
          <div
            css={
              isMobile
                ? [
                    css`
                      min-height: 100%;
                      ${tw`flex-1 w-full [height:100dvh] overflow-y-auto`}
                    `,
                  ]
                : [tw`w-1/2 `]
            }
          >
            {getStep.signUp}
          </div>
        )}
        {step !== "signUp" && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            css={
              isMobile
                ? [
                    css`
                      min-height: 100%;
                      ${tw`flex-1 w-full [height:100dvh] overflow-y-auto`}
                    `,
                  ]
                : [tw`w-1/2 `]
            }
          >
            {getStep[step]}
          </form>
        )}
      </StyledWrapperStepsModal>
    </Modal>
  );
};
export default AuthModal;
